import React, {useEffect, useState} from 'react';
import config from "../config";

function Profile(props) {
    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState([]);

    const [newRow, setNewRow] = useState({key: '', value: ''});
    const [editing, setEditing] = useState(false);

    const handleInputChange = (event, id, key) => {
        const newData = [...data];
        const index = newData.findIndex((item) => item.id === id);
        newData[index][key] = event.target.value;
        setData(newData);
    };

    const handleNewRowInputChange = (event, key) => {
        setNewRow({...newRow, [key]: event.target.value});
    };

    const handleAddRowClick = () => {
        const newId = data.length + 1;
        setData([...data, {id: newId, ...newRow}]);
        setNewRow({key: '', value: ''});
    };

    const handleSaveClick = () => {
        // Handle saving data
        console.log(data);
        const payload = {
            attributes: data
        };
        console.log(payload);
        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", "Bearer " + props.token);
        fetch(
            config['BACKEND_URL'] + "/api/v2/me",
            {
                method: 'PATCH',
                body: JSON.stringify(payload),
                headers: myHeaders
            }
        ).then(response => response.json())
            .then((result) => {
                console.log(result);
                if (result['status_code'] === 200) {
                    alert("Successfully Saved!");
                } else {
                    alert(result['detail']);
                }
            })
            .catch(error => console.log('error', error));

        console.log(data);
    };

    const handleEditClick = () => {
        setEditing(!editing);
    };

    const handleClearClick = () => {
        const newData = data.filter((row) => row.key && row.value);
        const newDataWithIds = newData.map((row, index) => ({...row, id: index + 1}));
        setData(newDataWithIds);
    };

    useEffect(() => {
        setIsLoading(true);

        // Fetch all items
        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", "Bearer " + props.token);

        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };

        fetch(config['BACKEND_URL'] + "/api/v2/me", requestOptions)
            .then(response => {
                return response.json();
            })
            .then(data => {
                console.log(data);
                const user_info = data['details']['attributes'];
                setIsLoading(false);
                setData(user_info);
            });
    }, []);

    if (isLoading) {
        return (
            <section>
                <button className="btn btn-primary" type="button" disabled>
                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    <span className="sr-only">Loading...</span>
                </button>
            </section>
        );
    }

    return (
        <div>
            <table className="table table-striped">
                <thead>
                <tr>
                    <th>No</th>
                    <th>Key</th>
                    <th>Value</th>
                </tr>
                </thead>
                <tbody>
                {data.map((row) => (
                    <tr key={row.id}>
                        <td>{row.id}</td>
                        <td>
                            {editing ? (
                                <input
                                    type="text"
                                    value={row.key}
                                    onChange={(event) => handleInputChange(event, row.id, 'key')}
                                />
                            ) : (
                                row.key
                            )}
                        </td>
                        <td>
                            {editing ? (
                                <input
                                    type="text"
                                    value={row.value}
                                    onChange={(event) => handleInputChange(event, row.id, 'value')}
                                />
                            ) : (
                                row.value
                            )}
                        </td>
                    </tr>
                ))}
                {editing && (
                    <tr>
                        <td></td>
                        <td>
                            <input
                                type="text"
                                value={newRow.key}
                                onChange={(event) => handleNewRowInputChange(event, 'key')}
                            />
                        </td>
                        <td>
                            <input
                                type="text"
                                value={newRow.value}
                                onChange={(event) => handleNewRowInputChange(event, 'value')}
                            />
                        </td>
                    </tr>
                )}
                </tbody>
            </table>
            <button onClick={handleAddRowClick}>Add Row</button>
            <button onClick={handleSaveClick}>Save</button>
            <button onClick={handleEditClick}>{editing ? 'Cancel' : 'Edit'}</button>
            <button onClick={handleClearClick}>Clear</button>
        </div>
    );
}

export default Profile;
