// Copyright (C) Dulan Jayasuriya - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential
// Written by Dulan Jayasuriya <dulanjayasuriya@gmail.com>, 26 April 2023


import Login from "../components/layout/Login";
import 'react-toastify/dist/ReactToastify.css';


function LoginPage(props) {
    return (
        <Login setToken={props.setToken} setLoggedIn={props.setLoggedIn} />
    );
}

export default LoginPage;