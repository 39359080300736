import Item from "./Item";
import classes from "./ItemList.module.css";

function ItemList(props) {
  return (
    <ul className={classes.list}>
      {props.myitems.map((item) => (
        <Item key={item.key} data={item} onItemDelete={props.onItemDelete} />
      ))}
    </ul>
  );
}

export default ItemList;
