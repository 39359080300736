// Copyright (C) Dulan Jayasuriya - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential
// Written by Dulan Jayasuriya <dulanjayasuriya@gmail.com>, 24 April 2023


import PublicItem from "../components/item/PublicItem";
import {useEffect, useState} from "react";
import config from "../config";

function ViewItemPage() {
    const [isLoading, setIsLoading] = useState(true);
    const [loadedItem, setLoadedItem] = useState({});


    const queryParameters = new URLSearchParams(window.location.search)
    const uid = queryParameters.get("item_id")
    const url = config['BACKEND_URL'] + "/api/v2/item/found?item_id=" + uid;

    useEffect(() => {
        setIsLoading(true);
        fetch(url
        ).then(response => {
            return response.json();
        }).then(data => {
            console.log(data);
            setIsLoading(false);
            setLoadedItem(data);
        });
    }, []);

    if (isLoading) {
        return (
            <section>
                <button className="btn btn-primary" type="button" disabled>
                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    <span className="sr-only">Loading...</span>
                </button>
            </section>
        );
    }
    return (
        <div>
            <PublicItem data={loadedItem}/>
        </div>
    )

}

export default ViewItemPage;