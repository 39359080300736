import {Link} from "react-router-dom";
import classes from "./MainNavigation.module.css";
import {Button} from "react-bootstrap";

function MainNavigation(props) {


    return (
        <header className={classes.header}>
            <div className={classes.logo}>SmartScan!</div>
            <nav>
                <ul>
                    <li>
                        <Link to="/">Main page</Link>
                    </li>
                    <li>
                        <Link to="/additem">Add Item</Link>
                    </li>
                    <li>
                        <Link to="/profile">Profile</Link>
                    </li>
                    <li>
                        <Button className="btn btn-outline-info" onClick={props.logOutHandler}>Logout</Button>
                    </li>
                </ul>
            </nav>
        </header>
    );
}

export default MainNavigation;
