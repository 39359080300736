// Copyright (C) Dulan Jayasuriya - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential
// Written by Dulan Jayasuriya <dulanjayasuriya@gmail.com>, 17 April 2023
import ItemList from "../components/item/ItemList";
import {useEffect, useState} from "react";
import config from "../config";


function MainPage(props) {
    function refreshPage() {
        window.location.reload(false);
    }

    const [isLoading, setIsLoading] = useState(true);
    const [loadedItems, setLoadedItems] = useState([]);

    useEffect(() => {
        setIsLoading(true);

        // Fetch all items
        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", "Bearer " + props.token);

        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };

        fetch(config['BACKEND_URL'] + "/api/v2/me/items", requestOptions)
            .then(response => {
                return response.json();
            })
            .then(data => {
                console.log(data);
                const items = [];
                for (const key in data['items']) {
                    const item = {
                        key: key,
                        ...data['items'][key]
                    };
                    items.push(item);
                }
                setIsLoading(false);
                setLoadedItems(items);
            });
    }, []);

    // Delete Item function
    function deleteItem(item_id) {
        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", "Bearer " + props.token);

        var requestOptions = {
            method: 'DELETE',
            headers: myHeaders,
            redirect: 'follow'
        };

        fetch(config['BACKEND_URL'] + "/api/v2/me/items/" + item_id, requestOptions)
            .then(response => {
                return response.json();
            })
            .then(data => {
                console.log(data);
                alert("Item Deleted Successfully");
                refreshPage(); // Navigate back to the root page
            });
    }

    if (isLoading) {
        return (
            <section>
                <button className="btn btn-primary" type="button" disabled>
                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    <span className="sr-only">Loading...</span>
                </button>
            </section>
        );
    }
    return (
        <section>
            <h1>All Item Cards.</h1>
            <ItemList myitems={loadedItems} onItemDelete={deleteItem}/>
        </section>
    );
}

export default MainPage;