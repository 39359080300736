// Copyright (C) Dulan Jayasuriya - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential
// Written by Dulan Jayasuriya <dulanjayasuriya@gmail.com>, 19 April 2023
import { useRef } from "react";

import Card from "../ui/Card";
import classes from "./AddItemForm.module.css";

function AddItemForm(props)
{
    const titleInputRef = useRef();
    const imageInputRef = useRef();
    const descriptionInputRef = useRef();
    const checkboxInputRef = useRef();

    function submitHandler(event) {
        event.preventDefault();
        const enteredTitle = titleInputRef.current.value;
        const enteredImage = imageInputRef.current.value;
        const enteredDescription = descriptionInputRef.current.value;
        const enteredOwnerShared = checkboxInputRef.current.checked;

        const itemData = {
            title: enteredTitle,
            image_url: enteredImage,
            description: enteredDescription,
            owner_details: enteredOwnerShared
        }

        console.log(itemData);
        props.onAddItem(itemData);
    }

    return (
        <Card>
        <form className={classes.form} onSubmit={submitHandler}>
            <div className={classes.control}>
                <label htmlFor='title'>Title</label>
                <input type="text" required id="title" ref={titleInputRef} />
            </div>
            <div className={classes.control}>
                <label htmlFor='image'>Item Image URL(Optional)</label>
                <input type="url" id="image" ref={imageInputRef} />
            </div>
            <div className={classes.control}>
                <label htmlFor='description'>Description</label>
                <textarea required id="description" rows='5' ref={descriptionInputRef} />
            </div>
            <div>
                <label htmlFor='contacts'>Share contact details  </label>&nbsp;&nbsp;
                <input type='checkbox' value='1' name='contacts' ref={checkboxInputRef} />
            </div>
            <div className={classes.actions}>
                <button>Add Item</button>
            </div>
        </form>
      </Card>
    );
}

export default AddItemForm;