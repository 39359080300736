import classes from "./Item.module.css";
import Card from "../ui/Card.js";
import {Button} from "react-bootstrap";

function Item(props) {
    const data = props.data;
    const codeUrl = 'https://find-me-app.herokuapp.com/api/v2/me/items/' + data.uid +
        '/tags?base_url=https://find-me-dulanj.vercel.app/item/found?item_id=';
    const openInNewTab = (url) => {
        window.open(url, "_blank", "noreferrer");
    };

    function deleteButtonHandler(event) {
        event.preventDefault();
        console.log(data.uid);
        props.onItemDelete(data.uid);
    }

    return (
        <li className={classes.item}>
            <Card>
                <div className={classes.content}>
                    <h3>{data.title}</h3>
                    <p> Tracking ID - {data.uid}</p>
                    <p> Scanned {data.scan_count} times</p>
                    <p><b>Description: </b>{data.description}</p>
                </div>
                <div className={classes.image}>
                    <img src={data.image_url} alt={data.title}/>
                </div>
                <div className={classes.actions}>
                    <Button variant="success" role="link" onClick={() => openInNewTab(codeUrl)}> Download QR Code </Button>
                    <Button variant="danger" onClick={deleteButtonHandler}> Delete </Button>
                </div>
            </Card>
        </li>
    );
}

export default Item;
