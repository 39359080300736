// Copyright (C) Dulan Jayasuriya - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential
// Written by Dulan Jayasuriya <dulanjayasuriya@gmail.com>, 04 May 2023


import {useRef} from "react";
import {Button} from "react-bootstrap";
import config from "../config";

function VerificationPage() {
    const queryParameters = new URLSearchParams(window.location.search)
    const code = queryParameters.get("code")
    const codeRef = useRef();


    function buttonClickHandler(event) {
        event.preventDefault();
        const enteredCode = codeRef.current.value;
        const url = config['BACKEND_URL'] + "/api/v2/verify?code=" + enteredCode;
        console.log(url);

        fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            }
        }).then(response =>
            response.json()
        ).then(data => {
            console.log(data);
            if (data.status_code === 200) {
                alert('Email verified successfully')
                window.location.href = '/';
            } else {
                alert(data.detail);
            }
        })
    }
        return (
            <section>
                <h3>Email Verification</h3>
                <div>
                    <label htmlFor="code">Verification Code:</label>
                    <input type="text" required id="fname" defaultValue={code} name="code" ref={codeRef}/>&nbsp;&nbsp;
                    <Button variant="success" size="sm" onClick={buttonClickHandler}>Verify</Button>
                </div>
                ]
            </section>
        );
    }
    export default VerificationPage;