import {Route, Routes} from "react-router-dom";
import MainPage from "./pages/MainPage";
import Profile from "./pages/Profile";
import AddItem from "./pages/AddItem";
import ViewItemPage from "./pages/ViewItemPage";
import LoginPage from "./pages/LoginPage";
import {useState} from "react";
import Layout from "./components/layout/Layout";
import VerificationPage from "./pages/VerificationPage";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function setToken(userToken) {
    sessionStorage.setItem('token', JSON.stringify(userToken));
}

function getToken() {
    const tokenString = sessionStorage.getItem('token');
    const userToken = JSON.parse(tokenString);
    return userToken?.access_token
}

function App() {

    let [loggedIn, setLoggedIn] = useState(false)
    const token = getToken();

    function logoutHandler(event) {
        console.log("logoutHandler");
        event.preventDefault();
        sessionStorage.removeItem('token');
        setLoggedIn(false);
    }

    function setLoggedInHandler(value) {
        setLoggedIn(value)
    }

    if (loggedIn) {
        return (
            <div>
                <Layout logOutHandler={logoutHandler}>
                    <Routes>
                        <Route path="/" element={<MainPage token={token}/>}/>
                        <Route path="/additem" element={<AddItem token={token}/>}/>
                        <Route path="/profile" element={<Profile token={token}/>}/>
                        <Route path="/item/found" element={<ViewItemPage/>}/>
                    </Routes>
                </Layout>
                <ToastContainer />
            </div>
        );
    } else {
        return (
            <div>
                <Routes>
                    <Route path="/" element={<LoginPage setToken={setToken} setLoggedIn={setLoggedInHandler}/>}/>
                    <Route path="/item/found" element={<ViewItemPage/>}/>
                    <Route path="/verify" element={<VerificationPage/>}/>
                </Routes>
                <ToastContainer />
            </div>

        );
    }
}

export default App;
