// Copyright (C) Dulan Jayasuriya - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential
// Written by Dulan Jayasuriya <dulanjayasuriya@gmail.com>, 26 April 2023


import {useState} from "react";
import config from "../../config";
import {toast} from "react-toastify";

function Login(props) {
    let [authMode, setAuthMode] = useState("signin")

    const changeAuthMode = () => {
        setAuthMode(authMode === "signin" ? "signup" : "signin")
    }

    const [buttonLoading, setButtonLoading] = useState(false);
    const [username, setUsername] = useState('');
    const [full_name, setFullName] = useState('');
    const [password, setPassword] = useState('');

    function signInHandler(event) {
        setButtonLoading(true);
        event.preventDefault()
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        myHeaders.append("Accept", "application/json");

        var urlencoded = new URLSearchParams();
        urlencoded.append("username", username);
        urlencoded.append("password", password);
        urlencoded.append("grant_type", "password");
        urlencoded.append("scope", "");
        urlencoded.append("client_id", "");
        urlencoded.append("client_secret", "");

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: urlencoded,
            redirect: 'follow'
        };

        fetch(config['BACKEND_URL'] + "/api/v2/signin", requestOptions)
            .then(response => response.json())
            .then(
                (result) => {
                    console.log(result);
                    if (result['token_type'] === "bearer") {
                        props.setToken(result);
                        props.setLoggedIn(true);
                    } else {
                        toast.error(result['detail'], {position: toast.POSITION.TOP_CENTER});
                    }
                }
            )
            .catch(error => console.log('error', error))
            .finally(() => {
                setButtonLoading(false);

            });
    }

    function signUpHandler(event) {
        setButtonLoading(true);
        event.preventDefault();
        console.log("Login Page - Sign Up Handler")

        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Accept", "application/json");

        var raw = JSON.stringify({
            "username": username,
            "password": password,
            "full_name": full_name
        });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch(config['BACKEND_URL'] + "/api/v2/signup", requestOptions)
            .then(response => response.json())
            .then((result) => {
                console.log(result);
                if (result['status_code'] === 200) {
                    toast.success(result['detail'], {position: toast.POSITION.TOP_CENTER});
                } else {
                    toast.error(result['detail'], {position: toast.POSITION.TOP_CENTER});
                }
            })
            .catch(error => console.log('error', error))
            .finally(() => {
                setButtonLoading(false);

            });
        ;
    }


    if (authMode === "signin") {
        return (
            <div className="Auth-form-container">
                <form className="Auth-form">
                    <div className="Auth-form-content">
                        <h3 className="Auth-form-title">Sign In</h3>
                        <div className="text-center">
                            Not registered yet?{" "}
                            <span className="link-primary" onClick={changeAuthMode}>
                Sign Up
              </span>
                        </div>
                        <div className="form-group mt-3">
                            <label>Email address</label>
                            <input
                                type="email"
                                className="form-control mt-1"
                                placeholder="Enter email"
                                value={username}
                                onChange={(e) => setUsername(e.target.value)}
                            />
                        </div>
                        <div className="form-group mt-3">
                            <label>Password</label>
                            <input
                                type="password"
                                className="form-control mt-1"
                                placeholder="Enter password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                            />
                        </div>
                        <div className="d-grid gap-2 mt-3">
                            <button type="submit" className="btn btn-primary" disabled={buttonLoading}
                                    onClick={signInHandler}>
                                Submit
                            </button>
                        </div>
                        <p className="text-center mt-2">
                            Forgot <a href="#">password?</a>
                        </p>
                    </div>
                </form>
            </div>
        )
    }

    return (
        <div className="Auth-form-container">
            <form className="Auth-form">
                <div className="Auth-form-content">
                    <h3 className="Auth-form-title">Sign In</h3>
                    <div className="text-center">
                        Already registered?{" "}
                        <span className="link-primary" onClick={changeAuthMode}>
              Sign In
            </span>
                    </div>
                    <div className="form-group mt-3">
                        <label>Full Name</label>
                        <input
                            type="text"
                            className="form-control mt-1"
                            placeholder="e.g Jane Doe"
                            value={full_name}
                            onChange={(e) => setFullName(e.target.value)}
                        />
                    </div>
                    <div className="form-group mt-3">
                        <label>Email address</label>
                        <input
                            type="email"
                            className="form-control mt-1"
                            placeholder="Email Address"
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                        />
                    </div>
                    <div className="form-group mt-3">
                        <label>Password</label>
                        <input
                            type="password"
                            className="form-control mt-1"
                            placeholder="Password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                    </div>
                    <div className="d-grid gap-2 mt-3">
                        <button type="submit" className="btn btn-primary" disabled={buttonLoading}
                                onClick={signUpHandler}>
                            Submit
                        </button>
                    </div>
                    <p className="text-center mt-2">
                        Forgot <a href="#">password?</a>
                    </p>
                </div>
            </form>
        </div>
    )
}

export default Login;