// Copyright (C) Dulan Jayasuriya - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential
// Written by Dulan Jayasuriya <dulanjayasuriya@gmail.com>, 19 April 2023


import AddItemForm from "../components/item/AddItemForm";
import {useNavigate} from "react-router-dom";
import config from "../config";

function AddItem(props) {
    const navigate = useNavigate();

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", "Bearer " + props.token);
    function addItemHandler(itemData) {
        console.log(itemData);
        fetch(
            config['BACKEND_URL'] + "/api/v2/me/items",
            {
                method: 'POST',
                body: JSON.stringify(itemData),
                headers: myHeaders
            }
        ).then(() => {
            navigate('/'); // Navigate back to the root page
        });
    }
    return (
        <section>
            <h1>Add New Item</h1>
            <AddItemForm onAddItem={addItemHandler} />
        </section>
    );
}

export default AddItem;