// Copyright (C) Dulan Jayasuriya - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential
// Written by Dulan Jayasuriya <dulanjayasuriya@gmail.com>, 12 May 2023


const dev = {
  BACKEND_URL: "http://localhost:5000"
};

const prod = {
  BACKEND_URL: "https://find-me-app.herokuapp.com"
};

const config = {
  // Add common config values here
  MAX_ATTACHMENT_SIZE: 5000000,
  // Default to dev if not set
  ...(process.env.REACT_APP_STAGE === "dev" ? dev : prod),
};

export default config;